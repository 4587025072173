// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-graph-jsx": () => import("./../../../src/pages/graph.jsx" /* webpackChunkName: "component---src-pages-graph-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-players-jsx": () => import("./../../../src/pages/players.jsx" /* webpackChunkName: "component---src-pages-players-jsx" */),
  "component---src-pages-seasons-jsx": () => import("./../../../src/pages/seasons.jsx" /* webpackChunkName: "component---src-pages-seasons-jsx" */),
  "component---src-pages-versus-jsx": () => import("./../../../src/pages/versus.jsx" /* webpackChunkName: "component---src-pages-versus-jsx" */)
}

